.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__box {
        border: 2px solid #f5f5f5;
        border-radius: 5px;
        padding: 4rem 8rem;
        text-align: center;
    }
}