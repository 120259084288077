@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin responsive($breakpoint) {
    @if $breakpoint == phone {
        @media (max-width: 37.5em) { @content }; // 600px width < 600px
    }
    @if $breakpoint == ipad {
        @media (max-width: 47.5em) { @content }; // 760px width < 760px
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) { @content }; // 900px width < 900px
    }
    @if $breakpoint == tab-land {
        @media (max-width: 75em) { @content }; // 1200px width < 1200px
    }
    @if $breakpoint == big-desktop {
        // @media (min-width: 88.8125em) { @content }; // 1421px  width > 1800px
        @media (min-width: 112.5em) { @content }; // 1421px  width > 1800px
    }
}
