.layout {
    max-width: 94%;
    margin: 0 auto;
    border-radius: 4rem;
    background-color: rgba(255, 255, 255, 1);
    // padding: 4rem 7rem 6rem;

    @include responsive(tab-port) {
        max-width: 100%;
        border-radius: 0;
        // padding: 2rem 3.5rem 3rem;
    }
    
    @include responsive(big-desktop) {
        max-width: 1320px;
    }
}