.card {
    max-width: 400px;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    position: relative;
    margin-bottom: 60px;
    min-height: 46rem;

    &__image {
        max-height: 60%;
        position: relative;

        img {
            width: 100%;
        }
    }

    &__title {
        display: block;
        line-height: 32px;
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 300;
    }

    &__content {
        max-height: 40%;
        padding: 24px;
        border-radius: 0 0 2px 2px;
        overflow: hidden;
    }

    &__actions {
        background-color: inherit;
        border-top: 1px solid rgba(160,160,160,0.2);
        padding: 16px 24px;
        &:last-child {
            border-radius: 0 0 2px 2px;
        }
    }

    &__link {
        color: #ffab40;
        margin-right: 24px;
        transition: color .3s ease;
        text-transform: uppercase;
        text-decoration: none;
    }

    &__stack {
        margin-top: 8px;
        font-weight: bold;
    }
}

