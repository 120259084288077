@keyframes flipInFromLeft {
    0% { 
        -webkit-transform: translate(-1000px) rotateY(-90deg); 
        transform: translate(-1000px) rotateY(-90deg);
        opacity: 0.2; 
    }
}

.animate {
    transition: all .44s ease-in-out;
}

.transition-flip-in-right {
    transform-origin: 50% 50%;
    animation: flipInFromLeft .5s both ease-out;
}