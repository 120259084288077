*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This 16px in rem
    font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%

    @include responsive(tab-land) {
        font-size: 56.25%; // 1 rem = 9px; 9px/16px = 56.25%
    }

    @include responsive(tab-port) {
        font-size: 56.25%;; // 1 rem = 8px; 8px/16px = 50%
    }

    @include responsive(big-desktop) {
        font-size: 60%; // 1 rem = 12px; 12px/16px = 75%
    }
}

body {
    box-sizing: border-box;
    padding: 3rem;
    background-color: $color-grey-dark-2;

    @include responsive(tab-port) {
        padding: 0;
    }
}

a {
    &:link, &:visited {
        text-decoration: none;
    }
}