body {
    font-family: 'Poppins', Helvetica, sans-serif;
    font-weight: 400;
    line-height: 1.7;
    color: $color-grey-dark-3;
}

//  h1
.heading-primary {
    font-size: 4.8rem;
    line-height: 5.6rem;
    margin-top: 0;
    font-weight: 600;
    margin-bottom: 2rem;
    color: $color-grey-dark-4;

    @include responsive(phone) {
        font-size: 3.6rem;
        line-height: 4.2rem;
    }
}

// h2
.heading-secondary {
    font-size: 2.5rem;
    color: $color-grey-dark-4;
    font-weight: 600;
    line-height: 1.2;
    display: inline-block;
    position: relative;
    padding-bottom: .7rem;
    margin-bottom: 3rem;

    &::before {
        content: '';
        display: block;
        width: 100%;
        background-color: $color-grey-dark-7;
        position: absolute;
        height: 2px;
        bottom: 0;
    }

    &::after {
        content: '';
        display: block;
        width: 30%;
        background-color: $color-primary;
        position: absolute;
        height: 2px;
        bottom: 0;
    }
}

// h4
.heading-tertiary {
    font-size: $default-font-size;
    margin: 1rem 0 .7rem;
    color: $color-grey-dark-4;
    font-weight: 600;
}

// h4
.heading-sub {
    font-size: $default-font-size;
    color: $color-grey-dark-6;
    font-weight: 300;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

// h4
.heading-sub-1 {
    font-size: $default-font-size;
    color: $color-grey-dark-6;
    font-weight: 300;
    margin-bottom: 1rem;
}

.title {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    display: inline-block;
    padding-bottom: 1.3rem;

    &__link {
        color: $color-grey-dark-4;
    }
}

.paragraph {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75;
    color: $color-grey-dark-3;
}

.paragraph-lg {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.75;
    color: $color-grey-dark-3;
}

.paragraph-md {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1.75;
    color: $color-grey-dark-3;
}

.active {
    opacity: 1 !important;
}
