.section-profile {
    // padding: 2.5rem 10rem;
    // margin-top: 12rem;
    padding: 12rem 0 0;

    // @include responsive(tab-land) {
    //     padding: 0;
    // }

    @include responsive(ipad) {
        text-align: center;
        padding: 6rem 0 0;

        .paragraph {
          text-align: left;  
        }

        .u-margin-bottom-small { 
            margin-bottom: 3rem !important;
        }
    }

    // @include responsive(big-desktop) {
    //     padding: 2.5rem 10rem;
    // }
}