// COLORS
$color-primary: #007ced;

$color-grey-dark-1: #777777;
$color-grey-dark-2: #999999;
$color-grey-dark-3: #666666;
$color-grey-dark-4: #333333;
$color-grey-dark-5: #d5d5d5;
$color-grey-dark-6: #aaaaaa;
$color-grey-dark-7: #f5f5f5;
$color-grey-dark-8: #fcfcfc;
$color-grey-dark-9: #eeeeee;
$color-grey-dark-10: #f2f2f2;

$color-white: #ffffff;
$color-black: #000;
$color-black-1: #222;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

// FONT SIZE
$default-font-size: 1.6rem;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eeeeee;

