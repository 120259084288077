.profile {

    &__box {
        // min-height: 100%;
        // width: 100%;
        padding-bottom: calc(100% - 3.5rem);
        border-radius: 50%;
        position: relative;
        border: 18px solid $color-white;
        box-shadow: 0 0 25px 0 rgba(0,0,0,.1);
        overflow: hidden;
    }

    &__image {
        height: auto;
        width: auto;
        // background-image: url(../../img/cesar-rincon-1024x1024.jpg);
        background-image: url(https://res.cloudinary.com/dkhujqejh/image/upload/c_scale,w_2500/v1602875466/portfolio/SAMOHT_A023_e_dmsgad.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        transition: all .2s;

        // old picture
        // top: -18px;
        // bottom: -18px;
        // left: -18px;
        // right: -18px;

        // current profile pic - this will soon be changed
        top: -3px;
        bottom: -4px;
        left: -193px;;
        right: -18px;

        &:hover {
            background-position: calc(50% + -4.21154px) calc(50% + 4.05181px);
        }

        @include responsive(ipad) {
            top: -3px;
            bottom: -4px;
            left: -100px;
            right: -18px;
        }
    }
}