.row {
    max-width: $grid-width;
    margin: 0 auto;

    &:not(:last-child) {
        margin-bottom: $gutter-vertical;

        @include responsive(ipad) {
            margin-bottom: $gutter-vertical-small;
        }
    }

    @include responsive(ipad) {
        max-width: 50rem;
        padding: 0 3rem;
    }

    @include clearfix;

    [class^='col-'] {
        float: left;

        &:not(:last-child) {
            margin-right: $gutter-horizontal;

            @include responsive(ipad) {
                margin-right: 0;
                margin-bottom: $gutter-vertical-small;
            }
        }

        @include responsive(ipad) {
            width: 100% !important;
        }
    }

    // col-1-of-1
    .col-12 {
        width: 100%;
        margin-bottom: $gutter-vertical-small;
    }
    // col-1-of-2 
    .col-6 {
        width: calc((100% - #{$gutter-horizontal}) / 2);
    }
    // col-1-of-3
    .col-4 {
        width: calc((100% - (#{$gutter-horizontal} * 2)) / 3);
    }
    // col-1-of-4
    .col-3 {
        width: calc((100% - (#{$gutter-horizontal} * 3)) / 4);
    }
    // col-1-5-of-2
    .col-5 {
        width: calc((100% - (#{$gutter-horizontal} * 2)) / 2.5);
    }
    // ol-1-7-of-2
    .col-7 {
        width: calc((2 * (100% - (#{$gutter-horizontal} * 2)) / 3.5) + #{$gutter-horizontal});
    }
    // col-2-of-3
    .col-8 {
        width: calc((2 * (100% - (#{$gutter-horizontal} * 2)) / 3) + #{$gutter-horizontal});
    }
    // col-2-of-4
    .col-6-ex {
        width: calc(2 * ((100% - (#{$gutter-horizontal} * 3)) / 4) + #{$gutter-horizontal});
    }
    // col-3-of-4
    .col-9 {
        width: calc(3 * ((100% - (#{$gutter-horizontal} * 3)) / 4) + #{$gutter-horizontal} * 2);
    }
}