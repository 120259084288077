@import '../../assets/scss/abstracts/variables';
@import '../../assets/scss/abstracts/mixins';

.footer {
    background-color: $color-grey-dark-8;
    border-color: $color-grey-dark-9;
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;
    // margin: 6rem -7rem -6rem;
    padding: 1.5rem 4.5rem;
    border-top: 2px solid $color-grey-dark-10;
    display: block;
    clear: both;

    @include clearfix;

    @include responsive(ipad) {
        text-align: center;
        margin: 0;
        margin-top: 10rem;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    &__social {
        float: left;

        @include responsive(ipad) {
            float: none;
        }

        &-list {
            list-style: none;
        }

        &-item {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 4rem;
            }
        }

        &-link {
            &:link, &:visited {
                line-height: 5.2rem;
                font-size: 2rem;
                font-weight: 400;
                color: $color-grey-dark-4;
                opacity: .55;
                transition: all .3s;
            }

            &:hover, &:active {
                opacity: 1;
            }
        }
    }

    &__copyright {
        float: right;
        line-height: 5.2rem;
        font-size: $default-font-size;
        font-weight: 400;
        color: $color-grey-dark-4;
        opacity: .55;
        transition: all .3s;

        @include responsive(ipad) {
            float: none;
        }
    }
}